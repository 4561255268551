import React, { Fragment } from 'react';

type TextProps = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'sm' | 'xs' | 'bold';
  className?: string;
  value?: string | number | Date | undefined;
  children?: React.ReactNode;
};

export const Text = ({ type, className, value, children }: TextProps) => {
  const renderTextType = () => {
    let classNames;
    switch (type) {
      case 'h1':
        classNames = 'text-4xl font-bold sm:text-4xl whitespace-normal';
        return (
          <h1 className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </h1>
        );
      case 'h2':
        classNames = 'text-3xl font-bold sm:text-3xl whitespace-normal';
        return (
          <h2 className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </h2>
        );
      case 'h3':
        classNames = 'text-2xl font-bold sm:text-2xl whitespace-normal';
        return (
          <h3 className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </h3>
        );
      case 'h4':
        classNames = 'text-xl font-bold sm:text-xl whitespace-normal';
        return (
          <h4 className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </h4>
        );
      case 'body':
        classNames = 'text-base';
        return (
          <p className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </p>
        );
      case 'sm':
        classNames = 'text-sm';
        return (
          <p className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </p>
        );
      case 'xs':
        classNames = 'text-xs';
        return (
          <p className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </p>
        );
      case 'bold':
        classNames = 'font-bold';
        return (
          <p className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </p>
        );
      default:
        return (
          <p className={className ? `${classNames} ${className}` : classNames}>
            {value}
          </p>
        );
    }
  };

  return (
    <Fragment>
      {renderTextType()}
      {children}
    </Fragment>
  );
};
